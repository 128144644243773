import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
} from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { debounce } from "lodash";
import styled from "styled-components";
import { accountsConfig, learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import ReportsNavBar from "./ReportsNavBar";
import ReportSortModal from "../../includes/career-advisor/modal/ReportSortModal";
import NoData from "../../includes/jobdesk/NoData";
import SectionLoader from "../../helpers/SectionLoader";
import ReactPaginate from "react-paginate";
import SortModal from "../../includes/career-advisor/modal/SortModal";

function Reports() {
    const { state } = useContext(Context);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [toDay, setToday] = useState("");
    const [toDate, setToDate] = useState();
    const today = new window.Date();
    const [fromDate, setFromDate] = useState();
    const [dateAlert, setDateAlert] = useState(false);
    const [toLink, setToLink] = useState("?");
    const [isFilter, setFilter] = useState(false);
    const [isSort, setSort] = useState(false);
    const wrapperRef = useRef(null);
    const [notificationData, setNotificationData] = useState([]);
    const [isPageLoading, setPageLoading] = useState(false);
    const [isDropdown, setDropdown] = useState(false);
    const [activeClick, setActiveClick] = useState("Filter");
    const [isCategory, setCategory] = useState(false);
    const [isAuthor, setAuthor] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [pagination, setPagination] = useState({});

    //search params
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("blog_status") &&
            (filterParams.blog_status = searchParams.get("blog_status"));
        searchParams.get("sort") &&
            (filterParams.sort = searchParams.get("sort"));
        searchParams.get("author") &&
            (filterParams.author = searchParams.get("author"));
        searchParams.get("published_date") &&
            (filterParams.published_date = searchParams.get("published_date"));
        searchParams.get("from_date") &&
            (filterParams.from_date = searchParams.get("from_date"));
        searchParams.get("to_date") &&
            (filterParams.to_date = searchParams.get("to_date"));
        searchParams.get("category") &&
            (filterParams.category = searchParams.get("category"));
        return filterParams;
    };

    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    const clearAll = () => {
        setSearchWord("");
        setToday("");
        setFromDate("");
        setToDate("");
        setDropdown(false);
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        updateSearchParams(query);
    };

    const updateSearchParams = useCallback(
        debounce((query) => {
            setSearchParams((prevParams) => ({
                ...prevParams,
                q: query,
            }));
        }, 300),
        []
    );

    const sortingArticles = (value) => {
        setSearchParams({
            ...currentParams,
            sort: value,
        });
    };
    const handleDateFilter = (date) => {
        if (date !== "") {
            setSearchParams({
                ...currentParams,
                published_date: date,
            });
        } else {
            const updatedParams = { ...currentParams };
            delete updatedParams.published_date;
            setSearchParams(updatedParams);
        }
    };
    const handleDateRange = (date, range) => {
        let date_range = `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}`;
        if (range === "from") {
            setSearchParams({
                ...currentParams,
                from_date: date_range,
            });
        } else {
            setSearchParams({
                ...currentParams,
                to_date: date_range,
            });
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCategory(false);
    };

    const [isCount, setCount] = useState({});

    useEffect(() => {
        async function CountCall() {
            try {
                const response = await learnConfig.get(
                    `/doubt-hub/report-count-chief/`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );
                const { StatusCode, data, pagination_data } = response.data;

                if (StatusCode === 6000) {
                    setCount(data);
                } else {
                }
            } catch (error) {}
        }

        CountCall();
    }, []);

    useEffect(() => {
        setPageLoading(true);
        const params = getSearchParams();

        async function fetchDoubtslist() {
            try {
                const response = await learnConfig.get(
                    "/doubt-hub/listing-reported-questions-chief/",
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: params,
                    }
                );
                const { status_code, data } = response.data;

                if (status_code === 6000) {
                    setNotificationData(data.data);
                    setPagination(data.pagination_data);
                    setPageLoading(false);
                } else {
                    setPageLoading(false);
                }
            } catch (error) {
                setPageLoading(false);
            }
        }

        fetchDoubtslist();
    }, [currentPage, searchParams]);

    return (
        <>
            <Container>
                <TopContainer>
                    <Top>
                        <Heading>Reports</Heading>
                        <Content>
                            <span>Evaluate and manage here</span>  
                        </Content>
                    </Top>
                    <Index>
                        <EvaluateTags>
                            <small>
                                Total Doubts{" "}
                                <p>{isCount?.total_doubts ?? "--"}</p>
                            </small>
                            <small>
                                Total Reports{" "}
                                <p>{isCount?.total_reports ?? "--"}</p>
                            </small>
                            <small>
                                Pending Evaluations
                                <p>
                                    {isCount?.total_pending_evaluations ?? "--"}
                                </p>
                            </small>
                        </EvaluateTags>
                    </Index>
                </TopContainer>

                <TopDiv>
                    <NavContainer>
                        <SearchContainer>
                            <SearchBar>
                                <InputField
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </SearchBar>
                            <SearchImageContainer>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
                                    alt="icon"
                                />
                            </SearchImageContainer>
                        </SearchContainer>
                        <Sort>
                            <SortDiv
                                onClick={() => {
                                    setFilter(false);
                                    setSort(!isSort);
                                }}
                            >
                                <Smaall className="white">Sort by</Smaall>
                                <Spaan>
                                    <img
                                        src={
                                            require("../../../assets/images/sort.svg")
                                                .default
                                        }
                                        alt="Sort"
                                    />
                                </Spaan>
                            </SortDiv>
                        </Sort>
                        <SortModal
                            sortingArticles={sortingArticles}
                            isSort={isSort}
                            setSort={setSort}
                            wrapperRef={wrapperRef}
                        />
                        <TopFilter>
                            <CoverSet ref={wrapperRef}>
                                <Filters
                                    onClick={() => setDropdown(!isDropdown)}
                                >
                                    <FilterText>{activeClick}</FilterText>
                                    <FIlterImage>
                                        <img
                                            src={
                                                require("../../../assets/images/bda/Filter.svg")
                                                    .default
                                            }
                                            alt="Image"
                                        />
                                    </FIlterImage>
                                </Filters>
                                <Popup className={isDropdown ? "dropdown" : ""}>
                                    <FilterDiv>
                                        Filter
                                        <ArrowDiv
                                            onClick={() =>
                                                setDropdown(!isDropdown)
                                            }
                                        >
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                alt="close"
                                            />
                                        </ArrowDiv>
                                    </FilterDiv>
                                    <HrLine className="Exam Status"></HrLine>
                                    {/* {selectedAuthor && (
                                        <SelectDiv>
                                            <Select>
                                                <MainImg
                                                    style={{
                                                        backgroundImage: `url(${selectedAuthor.image})`,
                                                    }}
                                                ></MainImg>
                                                <MianText>
                                                    {selectedAuthor.name}
                                                </MianText>
                                                <CloseImg
                                                    onClick={handleCloseClick}
                                                >
                                                    <img
                                                        src={
                                                            require("../../../assets/images/closered.svg")
                                                                .default
                                                        }
                                                        alt="close"
                                                    />
                                                </CloseImg>
                                            </Select>
                                        </SelectDiv>
                                    )} */}
                                    {/* <SearchDiv
                                            onClick={() => {
                                                setCategory(!isCategory);
                                                setAuthor(false);
                                            }}
                                        >
                                            <SearchTxt
                                                type="text"
                                                value={searchWord}
                                                onChange={(e) =>
                                                    setSearchWord(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Search category"
                                            />
                                            <ArticleIcon>
                                                <img
                                                    src={
                                                        require("../../../assets/images/chat/search.svg")
                                                            .default
                                                    }
                                                    alt="searchimage"
                                                />
                                            </ArticleIcon>
                                        </SearchDiv> */}
                                    {/* {isCategory && (
                                            <SearchDivModal>
                                                {categorys.map((data) => (
                                                    <SearchContent
                                                        key={data.id}
                                                        onClick={() => {
                                                            handleCategoryClick(
                                                                data
                                                            );
                                                            setSearchWord("");
                                                            setSearchParams({
                                                                ...currentParams,
                                                                category:
                                                                    data.slug,
                                                            });
                                                        }}
                                                    >
                                                        <p>{data.title}</p>
                                                    </SearchContent>
                                                ))}
                                            </SearchDivModal>
                                        )} */}

                                    {/* {selectedCategory && (
                                            <SelectDiv>
                                                <Select>
                                                    <MianText>
                                                        {selectedCategory.title}
                                                    </MianText>
                                                    <CloseImg
                                                        onClick={
                                                            handleRemoveCategory
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                require("../../../assets/images/closered.svg")
                                                                    .default
                                                            }
                                                            alt="close"
                                                        />
                                                    </CloseImg>
                                                </Select>
                                            </SelectDiv>
                                        )}
                                        <TagContainer>
                                            <Tag>Artificial Intellegence</Tag>
                                            <TagIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                    alt="close"
                                                />
                                            </TagIcon>
                                        </TagContainer> */}
                                    <ContainerDiv>
                                        <Small>Date Range</Small>
                                        <SelectOption
                                            onClick={() => {
                                                setToday(
                                                    toDay == "today"
                                                        ? ""
                                                        : "today"
                                                );
                                                handleDateFilter(
                                                    toDay == "today"
                                                        ? ""
                                                        : "today"
                                                );
                                            }}
                                        >
                                            <Span>
                                                {toDay === "today" ? (
                                                    <SelectedImg>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                            alt="image"
                                                        />
                                                    </SelectedImg>
                                                ) : (
                                                    <UnSelectedImg>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                            alt="image"
                                                        />
                                                    </UnSelectedImg>
                                                )}
                                            </Span>
                                            <Text>Today</Text>
                                        </SelectOption>

                                        <SelectOption
                                            onClick={() => {
                                                setToday(
                                                    toDay == "this_week"
                                                        ? ""
                                                        : "this_week"
                                                );
                                                handleDateFilter(
                                                    toDay == "this_week"
                                                        ? ""
                                                        : "this_week"
                                                );
                                            }}
                                        >
                                            <Span>
                                                {toDay === "this_week" ? (
                                                    <SelectedImg>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                            alt="image"
                                                        />
                                                    </SelectedImg>
                                                ) : (
                                                    <UnSelectedImg>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                            alt="image"
                                                        />
                                                    </UnSelectedImg>
                                                )}
                                            </Span>
                                            <Text>This week</Text>
                                        </SelectOption>

                                        <SelectOption
                                            onClick={() => {
                                                setToday(
                                                    toDay == "this_month"
                                                        ? ""
                                                        : "this_month"
                                                );
                                                handleDateFilter(
                                                    toDay == "this_month"
                                                        ? ""
                                                        : "this_month"
                                                );
                                            }}
                                        >
                                            <Span>
                                                {toDay === "this_month" ? (
                                                    <SelectedImg>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/selected.svg"
                                                            alt="image"
                                                        />
                                                    </SelectedImg>
                                                ) : (
                                                    <UnSelectedImg>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/unselected.svg"
                                                            alt="image"
                                                        />
                                                    </UnSelectedImg>
                                                )}
                                            </Span>
                                            <Text>This month</Text>
                                        </SelectOption>
                                    </ContainerDiv>
                                    <HrLine className="Exam Status"></HrLine>
                                    <ContainerDiv>
                                        <Small>Pick Date</Small>
                                        <AccountFilter>
                                            <DatePickDiv>
                                                <DatePicker
                                                    format={"dd-MM-yy"}
                                                    dayPlaceholder="DD"
                                                    monthPlaceholder="MM"
                                                    yearPlaceholder="YY"
                                                    onChange={(value) => {
                                                        setFromDate(value);
                                                        handleDateRange(
                                                            value,
                                                            "from"
                                                        );
                                                    }}
                                                    value={fromDate}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    maxDate={today}
                                                />
                                            </DatePickDiv>
                                            to
                                            <DatePickDiv>
                                                <DatePicker
                                                    format={"dd-MM-yy"}
                                                    dayPlaceholder="DD"
                                                    monthPlaceholder="MM"
                                                    yearPlaceholder="YY"
                                                    onChange={(value) => {
                                                        setToDate(value);
                                                        handleDateRange(
                                                            value,
                                                            "to"
                                                        );
                                                    }}
                                                    value={toDate}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    maxDate={today}
                                                />
                                            </DatePickDiv>
                                        </AccountFilter>
                                    </ContainerDiv>
                                    <ButtonDiv>
                                        <ClearDiv onClick={clearAll} to="">
                                            Clear
                                        </ClearDiv>
                                        <ApplyDiv
                                            className={dateAlert && "disabled"}
                                            onClick={(e) => {
                                                setDropdown(false);
                                            }}
                                        >
                                            Apply
                                        </ApplyDiv>
                                    </ButtonDiv>
                                </Popup>
                            </CoverSet>
                        </TopFilter>
                    </NavContainer>
                </TopDiv>
                <ReportsNavBar />
                <BottomContainer>
                    <MainHead>
                        <MainSub>
                            <Contents type="slno">Sl No</Contents>

                            <Contents type="question">Questions</Contents>
                            <Contents type="ask">Posted by</Contents>
                            <Contents type="report">No: of Reports</Contents>
                            <Contents type="evaluation">
                                Evaluation status
                            </Contents>
                            <Contents type="subscription">Action</Contents>
                        </MainSub>
                    </MainHead>
                    {isPageLoading ? (
                        <SectionLoader />
                    ) : notificationData?.length > 0 ? (
                        <>
                            {notificationData.map((item, index) => (
                                <UlList>
                                    {pagination?.first_item <=
                                        pagination?.last_item && (
                                        <Li type="slno">
                                            {pagination?.first_item + index}
                                        </Li>
                                    )}

                                    <Li type="question">
                                        <h2>{item?.question_title}</h2>
                                    </Li>

                                    <Li type="ask">
                                        <ImageContainer>
                                            <img
                                                src={
                                                    item?.posted_by
                                                        ?.student_photo
                                                }
                                                alt="student"
                                            />
                                        </ImageContainer>
                                        <Student>
                                            <h3
                                                style={{
                                                    fontFamily:
                                                        "gordita_medium",
                                                    fontSize: "14px",
                                                    color: " #fff",
                                                }}
                                            >
                                                {item?.posted_by
                                                    ?.student_name ?? "--"}
                                            </h3>
                                            <p
                                                style={{
                                                    fontFamily:
                                                        "gordita_regular",
                                                    fontSize: "14px",
                                                    color: "#fe673a",
                                                }}
                                            >
                                                {item?.posted_by
                                                    ?.student_program ?? "--"}
                                            </p>
                                        </Student>
                                    </Li>
                                    <Li type="report">
                                        <h4>{item?.no_of_reports ?? "--"}</h4>
                                    </Li>
                                    <Li type="evaluation">
                                        <StatusBtn
                                            status={item?.evaluation_status}
                                        >
                                            {item?.evaluation_status ==
                                            "not_evaluated"
                                                ? "Not Evaluted"
                                                : "--"}
                                        </StatusBtn>
                                    </Li>

                                    <Li type="subscription">
                                        <ViewButton
                                            to={`/doubt-hub/reports/questions/${item?.question}/`}
                                            className="sub"
                                        >
                                            View Reports
                                        </ViewButton>
                                    </Li>
                                </UlList>
                            ))}
                        </>
                    ) : (
                        <NoData />
                    )}
                </BottomContainer>
                {pagination?.total_pages > 0 && (
                    <PaginationContainer>
                        <PaginationText>
                            {pagination?.last_item} of {pagination?.total_items}
                        </PaginationText>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pagination?.total_pages}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                )}
            </Container>
        </>
    );
}

export default Reports;

const Date = styled.div`
    h2 {
        font-family: "gordita_medium";
        font-size: 16px;
    }
`;
const ImageContainer = styled.div`
    margin-right: 5px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;

    img {
        width: 100%;
        height: 100%;
        display: block;
    }

    img {
        width: 100%;
        display: block;
    }
`;
const Student = styled.div`
    h2 {
        font-family: "gordita_medium";
        font-size: 16px;
    }
    p {
        font-family: "gordita_medium";
        font-size: 16px;
    }
`;

const Container = styled.div`
    @media all and (max-width: 1350px) {
    }
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: column;
`;

const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const Top = styled.div``;
const Content = styled.div`
    margin-bottom: 14px;
    font-family: "gordita_reglar";
    span {
        color: #fff;
        font-size: 16px;
    }
`;
const Index = styled.div`
    border-bottom: 1px solid #2c2c2c;
`;
const EvaluateTags = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;

    small {
        font-family: "gordita_medium";
        padding: 24px;
        background: #1b1c1f;
        border: 2px solid #fe673a;
        width: 32%;
        border-radius: 12px;
        color: #fff;
        font-size: 16px;
    }
    p {
        font-family: "gordita_bold";
        color: #fff;
        font-size: 36px;
    }
`;

const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 10px;
    // @media all and (max-width: 1080px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    // @media all and (max-width: 640px) {
    //     grid-template-columns: 1fr;
    // }
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #2c2c2c;
`;
const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;

const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 8px 16px 8px 16px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
`;

const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
`;
const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;

const Contents = styled.div`
    display: flex;
    font-family: "gordita_medium";
    font-size: 16px;
    width: ${({ type }) =>
        type === "slno"
            ? "10%"
            : type === "question"
            ? "25%"
            : type === "ask"
            ? "20%"
            : type === "report"
            ? "20%"
            : type === "evaluation"
            ? "15%"
            : type === "subscription"
            ? "10%"
            : ""};
    justify-content: ${({ type }) =>
        type === "action" ? "center" : "flex-start"};
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
    border-bottom: 1px solid #252627;
`;
const StatusBtn = styled.div`
    color: ${({ status }) =>
        status === "not_evaluated" ? "#d0d5dd" : "#0FA76F"};
    border: 1px solid
        ${({ status }) => (status === "not_evaluated" ? "#d0d5dd" : "#0FA76F")};
    padding: 6px 8px;
    border-radius: 6px;
    font-size: 14px;
`;
const Li = styled.li`
    display: flex;
    font-family: "gordita_regular";
    text-transform: capitalize;
    white-space: nowrap;
    font-size: 16px;
    width: ${({ type }) =>
        type === "slno"
            ? "10%"
            : type === "question"
            ? "25%"
            : type === "ask"
            ? "20%"
            : type === "report"
            ? "20%"
            : type === "evaluation"
            ? "15%"
            : type === "subscription"
            ? "10%"
            : ""};
    h2 {
        width: 85%;
        color: #d2caca;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span {
        font-size: 14px;
        color: #fff;
        font-family: "gordita_medium";
        display: block;
    }
    small {
        font-size: 14px;
        color: #7b7b7b;
        font-family: "gordita_medium";
        display: block;
    }
    :last-child {
        justify-content: center;
    }
`;
const ViewButton = styled(Link)`
    border: 2px solid #fe673a;
    padding: 11px 16px 11px 16px;
    border-radius: 6px;
    font-size: 14px;
    justify-content: center;
    display: flex;
    color: #fff;
`;
const TagContainer = styled.div`
    padding: 8px 10px 8px 10px;
    background: #1d1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 56%;
    border-radius: 6px;
`;
const ClearDiv = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 154px;
    height: 40px;
    background: #1b1c20;
    border-radius: 6px;
    border: 1px solid #fe673a;
    margin-right: 15px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
`;
const Small = styled.span`
    color: #838488;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 15px;
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;
const Span = styled.div``;
const SelectOption = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    color: #fff;
    &.active {
        background: #161619;
        border-radius: 5px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #fe673a;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
`;
const ApplyDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 11px;
    width: 154px;
    height: 40px;
    background: #fe673a;
    border-radius: 6px;
    font-family: gordita_medium;
    cursor: pointer;
    color: #fff;
    &.disabled {
        cursor: not-allowed;
    }
`;
const TagIcon = styled.div`
    width: 12px;
    img {
        width: 100%;
        display: block;
    }
`;
const ContainerDiv = styled.div``;

const Tag = styled.div`
    font-family: "gordita_regular";
`;
const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;

const Filters = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const FilterText = styled.div`
    margin-right: 10px;
    font-family: gordita_medium;
    color: #fff;
    font-size: 14px;
`;
const FIlterImage = styled.div`
    & img {
        width: 12px;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    padding: 20px;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 384px;
    top: 0;
    right: -500px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        overflow: visible;
        background: #1b1c20;
        max-height: auto;
        transition: 0.5s ease;
        min-height: 100%;
        right: 0;
        overflow-y: scroll;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const FilterDiv = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    justify-content: space-between;
    align-items: center;
`;
const ArrowDiv = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 20px;

    transform: rotate(90deg);

    img {
        width: 100%;
        display: block;
    }
`;
const HrLine = styled.div`
    background: #26272a;
    height: 1px;
    margin: 10px 0;
`;
const SearchDiv = styled.div`
    display: flex;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #161619;
    display: flex;
    padding: 12px 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`;
const SearchTxt = styled.input`
    color: #fff;
    font-size: 18px;
    width: 100%;

    &::placeholder {
        color: #fff;
        font-size: 18px;
    }
`;
const ArticleIcon = styled.small`
    display: inline-block;
    width: 18px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;
const SelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const UnSelectedImg = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-size: 14px;
`;
const AccountFilter = styled.div`
    display: flex;
    align-items: center;
`;
const DatePickDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #161618;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #272729;
    font-size: 14px;
    width: 146px;
    height: 50px;
    cursor: pointer;
    z-index: unset !important;
    @media all and (max-width: 480px) {
        padding: 13px 5px;
    }

    & .react-date-picker__calendar {
        inset: inherit !important;
        top: -175px !important;
        left: -38px !important;
        z-index: 201;
        width: 375px !important;
    }

    &:last-child .react-date-picker__calendar {
        left: -218px !important;
    }

    &:last-child {
        margin: 0 0 0 10px;
    }

    &.active {
        border: 1px solid #fe673a;
    }
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px 16px 8px 16px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    border-radius: 3px;
    margin-right: 12px;
`;

const Sort = styled.button`
    position: relative;
    margin-right: 8px;
    img {
        display: block;
        width: 100%;
    }
`;
const SortDiv = styled.div`
    display: flex;
    height: 44px;
    padding: 11px 16px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    cursor: pointer;
`;
const Spaan = styled.span`
    width: 24px;
`;
const Smaall = styled.small`
    color: #7b7b7b;
    font-size: 0.875rem;
    font-family: gordita_medium;

    cursor: pointer;
    &.white {
        color: #fff;

        margin-right: 8px;
    }
    &.whites {
        color: #f2f2f2;

        line-height: normal;
        margin-right: 0;
        margin-left: 4px;
    }
`;

const SearchBar = styled.div`
    background: #1b1c1f;

    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #1b1c1f;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px 20px;
    cursor: pointer;
`;

const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;

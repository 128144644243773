import React, { useContext, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import VideoUpload from "../../../genaral/VideoUpload";
import ImageUploading from "../../../genaral/ImageUploading";
import { Context } from "../../../contexts/Store";
import { learnConfig } from "../../../../axiosConfig";

function CreateLanguageModal({
    isModal,
    isOpen,
    onClose,
    setModal,
    setIsOpen,
    setSelectedLanguageId,
    selectedLanguageId,
    syllabusLanguages,
    setTitle,
    title,
    setSelectedImage,
    selectedImage,
    handSubmit,
    isButtonLoading,
    description,
    setDescription,
    selectedForm,
    setVideoId,
    videoId,
    setVideoUrl,
    videoUrl,
    selectedVideoName,
    setErrorMessage,
    errorMassage,
    prof_id,
    id,
}) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isUploadLoading, setUploadLoading] = useState(false);

    // const [videoUrl, setVideoUrl] = useState("");

    /**
     * Clears all form fields related to creating a new language.
     */
    const handleClearForm = () => {
        setTitle("");
        setDescription("");
        setSelectedImage(null);
        setSelectedVideo(null);
        setSelectedLanguageId("");
    };
    useEffect(() => {
        if (errorMassage) {
            const timer = setTimeout(() => {
                setErrorMessage(false);
            }, 3000); // 500 milliseconds = 0.5 seconds

            // Cleanup the timer if the component is unmounted or errorMassage changes
            return () => clearTimeout(timer);
        }
    }, [errorMassage]);

    /** Handles the language change event.*/
    const handleLanguageChange = (e) => {
        console.log(e);
        setSelectedLanguageId(e.target.value);
    };

    /**
     * Handles the change event of the title input field.
     * */
    const handleTitleChange = (e) => {
        const inputValue = e.target.value;
        const truncatedTitle = inputValue.slice(0, 100);
        setTitle(truncatedTitle);
    };
    /**
     * Handles the change event of the description input field.
     * */
    const handleDescriptionChange = (e) => {
        const inputValue = e.target.value;
        console.log(inputValue, "BALUE");
        setDescription(inputValue);
    };

    /**
     * Toggles the checkbox state. */
    // const handleToggle = () => {
    //     setIsChecked(!isChecked);
    // };

    /**
     * Handles the video submission form.*/
    const handleVideoSubmit = async () => {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append("video", selectedVideo);
        formData.append("pk", prof_id || id);
        formData.append("type", prof_id ? "designation" : "topic");

        try {
            const response = await learnConfig.post(
                `/learn/sro/syllabus/video-upload-language-create/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );
            const { status_code, data, message } = response.data;
            if (status_code === 6000) {
                const { video_id } = data;
                setVideoId(video_id);
                // fetchLanguage();
                setUploadLoading(false);
            } else {
                if (message?.message) {
                    alert(message.message);
                    setUploadLoading(false);
                } else {
                    alert("Some error occurred");
                    setUploadLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setUploadLoading(false);
        }
    };

    /* File upload useffect */
    useEffect(() => {
        if (selectedVideo instanceof File) {
            handleVideoSubmit();
            setErrorMessage(false);
        }
    }, [selectedVideo]);

    if (!isOpen) return null;

    return (
        <ModalContainer>
            {isModal ? (
                <Overlay
                    onClick={() => {
                        setIsOpen(false);
                        onClose();
                    }}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modals>
                    <Top>
                        <ImageInto
                            onClick={() => {
                                handleClearForm();
                                onClose();
                            }}
                        >
                            <Img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-04-2024/into.svg"
                                alt="image"
                            />
                        </ImageInto>
                    </Top>
                    <Hr />
                    <Form
                        // handle form submission
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (title !== ``) {
                                handSubmit();
                            } else {
                                setErrorMessage("Title is required");
                            }
                        }}
                    >
                        <Select>
                            <Label>Language</Label>
                            <InputContainer>
                                <SelectButton
                                    onChange={handleLanguageChange}
                                    value={selectedLanguageId}
                                    length={syllabusLanguages?.length}
                                >
                                    {syllabusLanguages?.length > 0 && (
                                        <>
                                            {syllabusLanguages.map((item) => (
                                                <Option
                                                    key={item?.pk}
                                                    value={item?.pk}
                                                >
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </>
                                    )}
                                </SelectButton>
                            </InputContainer>
                        </Select>
                        
                        <Thumbnail>
                        <ImageUploading
                                Title="Thumbnail Image "
                                selectedImage={selectedImage}
                                setSelectedImage={setSelectedImage}
                                errorMassage={errorMassage}
                            />
                            {selectedForm === "profession-language-form" ||
                            selectedForm === "create-topic-detail-form" ||
                            selectedForm === "create-topic-detail-form" ? (
                                <Container>
                                    
                                    <Indro>Intro Video</Indro>
                                    <StyledInput
                                        type="text"
                                        placeholder="Paste Vimeo link here..."
                                        value={videoUrl}
                                        onChange={(e) =>
                                            setVideoUrl(e.target.value)
                                        }
                                    />
                                    <Indro>Video ID</Indro>
                                    <StyledInput
                                        type="text"
                                        placeholder="Enter vimeo video id"
                                        value={videoId}
                                        onChange={(e) =>
                                            setVideoId(e.target.value)
                                        }
                                    />
                                    {videoUrl && (
                                        <UploadedLink>
                                            <strong>Uploaded Link:</strong>{" "}
                                            <a
                                                href={videoUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {videoUrl}
                                            </a>
                                        </UploadedLink>
                                    )}
                                </Container>
                            ) : null}
                        </Thumbnail>

                        <Type>
                            <Title>
                                <Label>Title</Label>
                                <Input
                                    type="text"
                                    placeholder={"Type here"}
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                                {errorMassage && title === "" ? (
                                    <Error>This field is required</Error>
                                ) : null}
                            </Title>
                            <Title>
                                <Label>Description</Label>
                                <Textarea
                                    type="text"
                                    placeholder="Type here"
                                    cols={10}
                                    rows={6}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                                {errorMassage && description === "" ? (
                                    <Error>This field is required</Error>
                                ) : null}
                            </Title>
                        </Type>
                        {/* <CheckContainer>
                             <CheckBoxContainer
                                isChecked={isChecked}
                                onClick={handleToggle}
                            >
                                <CheckBox>
                                    {isChecked ? (
                                        <Img
                                            className="checked"
                                            src={
                                                require("../../../../../src/assets/images/checkmark.svg")
                                                    .default
                                            }
                                            alt="Checked Image"
                                        />
                                    ) : null}
                                </CheckBox>
                            </CheckBoxContainer> 
                            <Labels
                                isChecked={isChecked}
                                onClick={handleToggle}
                            >
                                Set as primary language
                            </Labels> 
                        </CheckContainer> */}
                        <Hr />
                        {errorMassage && errorMassage ? (
                            <Error>{errorMassage}</Error>
                        ) : null}
                        <ButtonContainer>
                            <CancelButton
                                onClick={() => {
                                    handleClearForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </CancelButton>

                            <UploadButton
                                isUploadLoading={isUploadLoading}
                                disabled={isUploadLoading}
                                type="submit"
                            >
                                {isButtonLoading ? <ButtonLoader /> : "Upload"}
                            </UploadButton>
                        </ButtonContainer>
                    </Form>
                </Modals>
            </Cover>
        </ModalContainer>
    );
}

export default CreateLanguageModal;
const Error = styled.div`
    font-size: 14px;
    color: red;
    margin-top: 6px;
`;

const Overlay = styled.button``;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const ModalContainer = styled.section`
    position: fixed;
    top: 0px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    padding-top: 250px;
    padding-bottom: 55px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    z-index: 1000;
`;
const Modals = styled.div`
    width: 95%;
    max-width: 640px;
    max-height: 80vh;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 101;
    border: 1px solid #26272a;
    padding: 20px;
    background: #1b1c1f;
    margin: 0 auto;
    overflow-y: scroll;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        width: 60%;
    }
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
        padding: 15px;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ImageInto = styled.div`
    width: 3%;
    cursor: pointer;
`;
const Img = styled.img`
    display: block;
    width: 100%;
    margin: 0 auto;
`;
const Hr = styled.hr`
    border-bottom: 1px solid #3b3b3b;
    width: 100%;
    margin: 15px 0;
`;
const Form = styled.form``;
const Select = styled.div``;
const Option = styled.option``;
const Label = styled.label`
    font-size: 14px;
    color: #cdd5df;
`;
const Input = styled.input`
    border: 1px solid #3b3b3b;
    padding: 15px 10px;
    font-size: 14px;
    border-radius: 9px;
    width: 100%;
    margin: 10px 0 0px;
    color: #fff;
    background: #161619;
`;
const InputContainer = styled.div`
    border: 1px solid #3b3b3b;
    font-size: 14px;
    border-radius: 9px;
    width: 100%;
    margin: 10px 0 20px;
    background: #161619;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SelectButton = styled.select`
    cursor: pointer;
    padding: 15px 10px;
    width: 100%;
    color: #fff;
    background-color: #1b1c1f;
`;

const Textarea = styled.textarea`
    border: 1px solid #3b3b3b;
    width: 100%;
    padding: 10px 17px;
    margin: 10px 0 0px;
    resize: none;
    border-radius: 9px;
    color: #fff;
    background: #161619;
`;

const Thumbnail = styled.div`
    display: flex;
`;

const Type = styled.div``;
const Title = styled.div`
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    gap: 15px;
`;
const CancelButton = styled.button`
    border: 1px solid #fe673a;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 17%;
    color: #fff;
    @media all and (max-width: 640px) {
        width: 27%;
    }
    @media all and (max-width: 480px) {
        width: 35%;
    }
`;
const UploadButton = styled.button`
    border: 1px solid #fe673a;
    padding: 10px 10px;
    border-radius: 5px;
    width: 17%;
    color: #fff;
    cursor: ${({ isUploadLoading }) =>
        isUploadLoading ? "not-allowed" : "pointer"};
    background-color: #fe673a;
    @media all and (max-width: 640px) {
        width: 27%;
    }
    @media all and (max-width: 480px) {
        width: 35%;
    }
`;
const CheckContainer = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
`;
const CheckBoxContainer = styled.div`
    border: 1px solid #fb663a;
    padding: ${({ isChecked }) => (isChecked ? "1px" : "10px")};
    background-color: #fff;
    border-radius: 4px !important;
    cursor: pointer;
`;
const CheckBox = styled.div`
    width: 100%;
`;
const Labels = styled.label`
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 4px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    max-width: 400px;
    /* margin: auto; */
    width: 55%;
`;

const Indro = styled.label`
    font-size: 14px;
    color: rgb(205, 213, 223);
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgb(59, 59, 59);
    color: white;
    font-size: 14px;
    outline: none;
    background: rgb(22, 22, 25);

    &::placeholder {
        color: #bbb;
    }
`;

const UploadedLink = styled.p`
    font-size: 14px;
    color: #00e676;
    padding: 8px;
    border-radius: 5px;
    text-align: center;

    a {
        color: #00bcd4;
        text-decoration: none;
        font-weight: bold;
    }

    a:hover {
        text-decoration: underline;
    }
`;
